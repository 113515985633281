import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FaPlus } from 'react-icons/fa';

import { Breadcrumb } from '@/components';
import { ReportModal } from '@/components/ReportModal';
import { Button, FlatButton } from '@/components/ui/button';

// import { Select as AllSelect } from '../../../components/AllSelect';
import { getReportModalTranslation } from '@/componentsTranslation/reportModal';
import documentApi from '@/services/api/document';
import userApi from '@/services/api/user';

import { Can, useAbility } from '../../../hooks/ability';
import { UserTable } from './user.table';

export const UserPage: React.FC = () => {
  const { t } = useTranslation(['translation', 'admin']);
  const ability = useAbility();

  const [isExporting, setIsExporting] = useState(false);

  return (
    <div className="flex h-full w-full flex-col overflow-y-hidden">
      <Breadcrumb items={[{ title: t('breadcrumb.admin') }, { title: t('breadcrumb.users') }]} />

      <div className="flex h-full flex-col gap-2 overflow-y-hidden p-4">
        <div className="flex justify-between">
          <div className="text-xl font-bold text-primary-400">{t('pages.admin.user.title_page')}</div>

          <div className="flex items-center gap-2">
            <Can I="admin.user.report.index">
              <Button variant="default" onClick={() => setIsExporting(true)}>
                {t('export-users')}
              </Button>
            </Can>
            <Can I="admin.user.store" a="">
              <a href="admin/user/new" target="_blank" rel="noreferrer">
                <FlatButton>
                  <span className="flex items-center gap-2">
                    <FaPlus />
                    {t('pages.admin.user.buttonAdd')}
                  </span>
                </FlatButton>
              </a>
            </Can>
          </div>
        </div>

        <UserTable />
      </div>

      <ReportModal
        translation={getReportModalTranslation(t)}
        withDateFilter
        width="50%"
        visibility={isExporting}
        afterClose={() => setIsExporting(false)}
        listReportApi={
          ability.can('admin.user.report.index', '') ? userApi.listReport : () => new Promise(resolve => resolve([]))
        }
        generateReportPermission="admin.user.report.store"
        generateReportApi={userApi.generateReport}
        downloadReportApi={documentApi.download}
        rangeLimit={180}
      />
    </div>
  );
};
