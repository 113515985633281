import React, { useEffect, useState } from 'react';
import { FaFilter } from 'react-icons/fa';

import { TablePaginationConfig, ColumnType } from 'antd/lib/table';
import { SorterResult, TableLocale, ColumnGroupType } from 'antd/lib/table/interface';
import { ThemeProvider } from 'styled-components';

import theme from '../../styles/theme';
import { defaultPagination, defaultTableWidth, defaultTranslation } from './constants';
import { FilterDropdown } from './FilterDropdown';
import { usePagination } from './hooks/usePagination';
import { useTable } from './hooks/useTable';
import { Container, STable, SwitchWrapper } from './styles';
import { Switch } from './Switch';
import { SearchParamsProps, TableProps } from './types';

const makeLocale = (translation: TableLocale) => ({
  filterConfirm: translation.filterConfirm,
  filterReset: translation.filterReset,
  emptyText: translation.emptyText,
});

export const TableData = ({
  columns = [],
  pagination: paginationConfig,
  tableConfig,
  translation = defaultTranslation,
  width = defaultTableWidth,
  customColumns,
  searchRequest,
  onChange,
  controller,
  searchFilters,
  dataSource,
  ...rest
}: TableProps<object>) => {
  const { pagination, updatePagination } = usePagination({
    tableConfig,
    paginationConfig,
    translation: translation?.pagination ?? {},
  });

  const { data, setData, shouldRefresh, setShouldRefresh } = controller ?? useTable();

  const [searchParams, setSearchParams] = useState<SearchParamsProps>(tableConfig?.searchConfig ?? {});
  const [isFetching, setIsFetching] = useState(false);
  const [currentSearchFilters, setCurrentSearchFilters] = useState<any>({});
  const [sorter, setSorter] = useState<any>([]);

  const locale: TableLocale = makeLocale(translation);

  const handleSearch = (execFilter: any) => execFilter();
  const handleReset = (clearFilters: any) => clearFilters();

  const refreshTable = () =>
    handleTableChange(pagination || ({} as TablePaginationConfig), currentSearchFilters, sorter);

  const getColumnSearchProps = (title: string) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm: execFilter, clearFilters }: any) => (
      <FilterDropdown
        translation={translation?.filterDropdown ?? {}}
        buttonClearOnClick={() => handleReset(clearFilters)}
        buttonSearchOnClick={() => handleSearch(execFilter)}
        inputOnChange={e => {
          setSelectedKeys(e.target.value ? [e.target.value] : []);
        }}
        inputOnPressEnter={() => handleSearch(execFilter)}
        inputValue={selectedKeys[0]}
        title={title}
      />
    ),
  });

  const getFilteredValue = (column: any) =>
    searchParams
      ? searchParams.filtered
        ? searchParams.filtered[column.dataIndex]
        : searchParams.search || null
      : null;

  const getSortOrder = (column: any) => {
    const sorter = (searchParams?.sorter as SorterResult<object>) ?? null;
    if (sorter && sorter.columnKey === column.dataIndex) {
      return sorter.order;
    }

    return undefined;
  };

  const getFilterIcon = (filtered: boolean) => (
    <span className="anticon anticon-filter">
      <FaFilter color={filtered ? theme.colors.primary : theme.colors.greys.grey01} />
    </span>
  );

  const tableColumns = columns.map((column: any) => {
    let columnRender = {
      ...column,
      title: column.title,
      filteredValue: getFilteredValue(column),
    };

    if (customColumns)
      columnRender = {
        ...columnRender,
        ...customColumns(column),
      };

    if (column.key === 'action') {
      if (tableConfig?.getActions)
        columnRender = {
          ...columnRender,
          ...tableConfig.getActions(),
        };
    }

    if (column.sorter) {
      columnRender = {
        ...columnRender,
        sortOrder: getSortOrder(column),
      };
    }

    if (column.filtered) {
      columnRender = {
        ...columnRender,
        filteredValue: getFilteredValue(column),
        filterIcon: getFilterIcon,
        ...getColumnSearchProps(column.title?.toString() ?? ''),
      };
    }

    if (column.key === 'active') {
      const filteredValue =
        columnRender.filteredValue !== undefined && columnRender.filteredValue !== null
          ? [columnRender.filteredValue.toString()]
          : [searchParams?.initialFilter?.active ?? '1'];

      columnRender = {
        ...columnRender,
        filteredValue,
        width: '120px',
        filterMultiple: false,
        defaultFilteredValue: filteredValue,
        filters: [
          {
            text: translation?.filterDropdown?.filterActive ?? 'Active',
            value: '1',
          },
          {
            text: translation?.filterDropdown?.filterInactive ?? 'Inative',
            value: '0',
          },
        ],
        render: (active: boolean, record: any, index: number) => {
          return (
            <SwitchWrapper colors={tableConfig?.switchColor ?? {}}>
              <Switch
                key={index}
                onChange={(value, e) => {
                  if (tableConfig?.handleSwitchChange) tableConfig.handleSwitchChange(record, value, e);
                }}
                checked={active}
              />
            </SwitchWrapper>
          );
        },
      };
    }

    return columnRender;
  });

  const handleTableChange = async (
    pagination: TablePaginationConfig,
    filters: Record<string, React.Key[] | null>,
    sorter: SorterResult<object> | SorterResult<object>[],
  ) => {
    if (searchRequest) {
      const newSorter = {} as { [name: string]: string };
      const { field, order } = sorter as SorterResult<object>;

      if (sorter) {
        if (field) newSorter.sort = field.toString();

        if (order === 'ascend') newSorter.direction = 'asc';
        if (order === 'descend') newSorter.direction = 'desc';
      }

      setSorter(newSorter);
      setCurrentSearchFilters({ ...filters, ...searchFilters });

      try {
        setIsFetching(true);
        const { data, total } = await searchRequest(pagination, { ...filters, ...searchFilters }, newSorter);
        updatePagination(pagination, total);
        setData(data ?? []);
        setSearchParams({
          ...searchParams,
          filtered: { ...filters, ...searchFilters },
          sorter,
        });
      } finally {
        setIsFetching(false);
      }
    }
  };

  useEffect(() => {
    (async () => {
      if (searchRequest) {
        const response = await searchRequest(
          { ...defaultPagination, ...paginationConfig },
          searchParams?.initialFilter ?? {},
          {},
        );
        setData(response?.data ?? []);
        updatePagination({ ...defaultPagination, ...paginationConfig }, response.total);
      }
    })();
  }, []);

  useEffect(() => {
    if (dataSource?.length) setData([...dataSource]);
  }, [dataSource]);

  useEffect(() => {
    if (shouldRefresh) {
      setShouldRefresh(false);
      refreshTable();
    }
  }, [shouldRefresh]);

  useEffect(() => {
    (async () => {
      if (searchRequest && searchFilters) {
        const newFilter = {
          ...searchFilters,
        };
        const response = await searchRequest({ ...defaultPagination, ...paginationConfig }, newFilter ?? {}, {});
        setData(response?.data ?? []);
        updatePagination({ ...defaultPagination, ...paginationConfig }, response.total);
        setCurrentSearchFilters(newFilter);
      }
    })();
  }, [searchFilters]);

  return (
    <ThemeProvider theme={theme}>
      <Container width={width}>
        <STable
          loading={isFetching}
          locale={locale}
          columns={tableColumns as (ColumnGroupType<object> | ColumnType<object>)[]}
          pagination={pagination}
          onChange={(pagination: TablePaginationConfig, filter: any, sort: any, extra: any) => {
            handleTableChange(pagination, filter, sort);
            if (onChange) onChange(pagination, filter, sort, extra);
          }}
          dataSource={data as object[]}
          {...rest}
        />
      </Container>
    </ThemeProvider>
  );
};
