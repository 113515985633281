import React, { KeyboardEvent, MouseEvent, ChangeEvent } from 'react';
import { FaSearch } from 'react-icons/fa';

import { Input, Space } from 'antd';
import { ThemeProvider } from 'styled-components';

import theme from '../../../styles/theme';
import { Button } from '../Button';
import { FilterDropdownTranslation } from '../types';
import { Container } from './styles';

interface FilterDropdownProps {
  title: string;
  inputOnChange(event: ChangeEvent<HTMLInputElement>): void;
  inputOnPressEnter(event: KeyboardEvent<HTMLInputElement>): void;
  inputValue: string;
  buttonClearOnClick(event: MouseEvent<HTMLInputElement>): void;
  buttonSearchOnClick(event: MouseEvent<HTMLInputElement>): void;
  translation: FilterDropdownTranslation;
}

export const FilterDropdown: React.FC<FilterDropdownProps> = ({
  title,
  inputOnChange,
  inputOnPressEnter,
  inputValue,
  buttonClearOnClick,
  buttonSearchOnClick,
  translation,
}) => {
  return (
    <ThemeProvider theme={theme}>
      <Container>
        <Input
          placeholder={`${translation.placeholderPrefix} ${title}`}
          value={inputValue}
          onPressEnter={inputOnPressEnter}
          onChange={inputOnChange}
        />
        <Space>
          <Button status="secondary" size="small" onClick={buttonClearOnClick}>
            {translation.resetButton}
          </Button>
          <Button icon={<FaSearch />} status="primary" size="small" onClick={buttonSearchOnClick}>
            {translation.searchButton}
          </Button>
        </Space>
      </Container>
    </ThemeProvider>
  );
};
