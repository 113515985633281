import React from 'react';

import IconWithTooltip from '@/components/IconWithTooltip';
import { TableData } from '@/compositions/TableDataAsync';
import { DTOReport } from '@/types/DTOReports';
import { Formatter } from '@/utils/Formatter';
import { Spin } from 'antd';

import columns from '../columns';
import { ReportTableProps } from './types';

export const ReportTable = ({
  fetchReportsToExport,
  translation,
  reportsProcessingList,
  handleDownloadReport,
  extraInputs,
  isFetchingReports,
  controller,
  handleChangePage,
}: ReportTableProps) => {
  return (
    <TableData
      rowKey="id"
      width="100%"
      controller={controller}
      searchRequest={fetchReportsToExport}
      columns={columns}
      pagination={{ position: ['bottomCenter'] }}
      translation={translation.table}
      onChange={handleChangePage}
      customColumns={(column: any) => {
        switch (column.key) {
          case 'created_at':
            column = {
              ...column,
              title: translation.columns?.date,
              render: (_: any, row: DTOReport) => {
                return row.created_at ? Formatter.datetime(row.created_at) : '-';
              },
            };
            break;
          case 'user':
            column = {
              ...column,
              title: translation.columns?.userName,
              render: (_: any, row: DTOReport) => {
                return row.user ? row.user.name : '-';
              },
            };
            break;

          case 'name':
            column = {
              ...column,
              title: translation.columns?.fileName,
            };
            break;

          case 'status':
            column = {
              ...column,
              title: translation.columns?.status,
              render: (_: any, row: DTOReport) => {
                return row.status ? translation.status![row.status] : '';
              },
            };
            break;
          case 'action':
            column = {
              ...column,
              title: translation.columns?.options,
              render: (_: number, record: DTOReport) =>
                record.status !== 'failed' && (
                  <Spin spinning={record.id ? reportsProcessingList.includes(record.id) : false}>
                    <IconWithTooltip
                      action="download"
                      title={translation.buttons?.download ?? ''}
                      onClick={() => handleDownloadReport(record)}
                    />
                    {extraInputs && (
                      <IconWithTooltip
                        action="csv"
                        title={translation.buttons?.download ?? ''}
                        onClick={async () => handleDownloadReport(record, true)}
                      />
                    )}
                  </Spin>
                ),
            };
            break;
          default:
            return column;
        }
        return column;
      }}
      loading={isFetchingReports}
    />
  );
};
