import { UserConfig } from '../../types/User/UserData';
import api from './api';

export default {
  config: {
    update: async (id: string, config: UserConfig, params = {}): Promise<UserConfig> => {
      return api.post(`admin/user/${id}/config`, { config, locale: config.app.language, _method: 'PATCH' }, params);
    },
  },
  listReport: async (params = {}): Promise<any> => {
    return api.get(`admin/user/report`, params);
  },
  generateReport: async (params = {}): Promise<any> => {
    return api.post(`admin/user/report`, params);
  },
};
