import React from 'react';

import { SSwitch } from './styles';
import { SwitchProps } from './types';

type Ref = HTMLElement;

export const Switch = React.forwardRef<Ref, SwitchProps>((props, ref) => {
  return <SSwitch {...props} ref={ref} />;
});
