import React, { useState } from 'react';

import FormItem from '@/components/FormItem';
import appConfigs from '@/configs/app';
import { Col, DatePicker, Form, Input, Modal } from 'antd';
import ptBR from 'antd/es/date-picker/locale/pt_BR';
import moment from 'moment';

import Row from '../../Row';
import { NewReportModalProps } from './types';

export const NewReportModal = ({
  visibleDate,
  translation,
  setVisibleDate,
  reportApiId,
  handleGenerateReport,
  withDateFilter,
  extraInputs,
  rangeLimit,
  customFields,
}: NewReportModalProps) => {
  const [formDateRange] = Form.useForm();
  const [currentDate, setCurrentDate] = useState<(any | null)[] | null>([null, null]);

  const getDisabledDate = (dateToCheck: any) => {
    if (!currentDate || !rangeLimit) return false;
    if (currentDate[0]) {
      const isBefore = moment(dateToCheck).isBefore(currentDate[0], 'day');
      const isAfter = moment(dateToCheck).isAfter(
        moment(new Date(currentDate[0].toString())).add(rangeLimit, 'days'),
        'day',
      );
      return isBefore || isAfter;
    }

    if (currentDate[1]) {
      const isBefore = dateToCheck.isBefore(
        moment(new Date(currentDate[1].toString())).subtract(rangeLimit, 'days'),
        'day',
      );
      const isAfter = dateToCheck.isAfter(currentDate[1], 'day');
      return isBefore || isAfter;
    }

    return false;
  };

  const handleConfirm = () => {
    formDateRange
      .validateFields()
      .then((values: any) => {
        handleGenerateReport(reportApiId, {
          ...values,
          date_range:
            values.date_range?.map((date: any, index: number) =>
              moment(date)
                .hours(index === 1 ? 23 : 0)
                .minutes(index === 1 ? 59 : 0)
                .seconds(index === 1 ? 59 : 0)
                .format(),
            ) ?? undefined,
        });
        setVisibleDate(false);
        formDateRange.resetFields();
      })
      .catch(err => console.error(err));
  };

  const handleCancel = () => {
    setVisibleDate(false);
    formDateRange.resetFields();
  };

  return (
    <Modal
      visible={visibleDate}
      title={translation.newReport}
      closable
      onCancel={handleCancel}
      cancelText={translation.closeModal}
      okText={translation.send}
      onOk={handleConfirm}
    >
      <Form form={formDateRange}>
        {withDateFilter && (
          <>
            <Row>
              <Col>
                <FormItem
                  label={translation.filters?.date}
                  name="date_range"
                  rules={[{ required: true }]}
                  initialValue={[moment().subtract(30, 'days'), moment()]}
                >
                  <DatePicker.RangePicker
                    locale={ptBR}
                    format={appConfigs.formatDate}
                    onCalendarChange={value => setCurrentDate(value)}
                    disabledDate={value => rangeLimit && getDisabledDate(value)}
                  />
                </FormItem>
              </Col>
            </Row>
          </>
        )}
        {extraInputs && (
          <>
            <Row>
              <Col>
                <FormItem label={translation.headers?.user} name="name">
                  <Input />
                </FormItem>
              </Col>
            </Row>
            <Row>
              <Col>
                <FormItem label={translation.headers?.code} name="code">
                  <Input />
                </FormItem>
              </Col>
            </Row>
          </>
        )}
        <Row>{customFields}</Row>
      </Form>
    </Modal>
  );
};
