import { useState } from 'react';

export const useTable = () => {
  const [data, setData] = useState<unknown[]>([]);
  const [shouldRefresh, setShouldRefresh] = useState(false);

  const refresh = () => setShouldRefresh(true);

  return { data, setData, shouldRefresh, refresh, setShouldRefresh };
};
