import React from 'react';

import { ButtonProps } from 'antd/lib/button';
import { ThemeProvider } from 'styled-components';

import theme from '../../../styles/theme';
import { SBtn } from './styles';
import { ButtonCustomProps } from './types';

export const Button: React.FC<ButtonProps & ButtonCustomProps> = props => {
  return (
    <ThemeProvider theme={theme}>
      <SBtn size="large" {...props} />
    </ThemeProvider>
  );
};
