import { useState } from 'react';

import { TablePaginationConfig } from 'antd/lib/table';

import { defaultPagination } from '../constants';
import { PaginationProps } from './types';

export const usePagination = ({ translation, paginationConfig }: PaginationProps) => {
  const [pagination, setPagination] = useState<false | TablePaginationConfig>({
    ...defaultPagination,
    ...paginationConfig,
  });

  const updatePagination = (newPagination: TablePaginationConfig, total: number) => {
    setPagination({
      ...newPagination,
      showTotal: () => `${translation.totalOfRegisters} ${total}`,
      total,
    });
  };

  return {
    pagination,
    updatePagination,
  };
};
